import * as React from "react";
import { Link } from "gatsby";

// markup
const NotFoundPage = () => {
  return (
    <main className="min-h-screen w-full flex justify-center items-center">
      <div className="text-center pb-8">
        <h1 className="text-gray-100 text-4xl font-bold mb-6">
          Stránka nenalezena
        </h1>
        <Link
          className="text-gray-200 hover:text-white focus:text-white focus:outline-none transition underline text-lg tracking-wide"
          to="/"
        >
          Zpět na úvodní stránku
        </Link>
      </div>
    </main>
  );
};

export default NotFoundPage;
